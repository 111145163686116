import NotificationAlert from '@/components/atoms/alert';
import NotificationBell from '@/components/atoms/notification-bell';
import { logonEventRedirect } from '@/components/molecules/header-notification/utils';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { getItem } from '@/helpers/session-storage';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { useGetLatestApplicationIdQuery } from '@/store/queries/workflows';
import {
  useGetNotificationsQuery,
  useGetTasksQuery,
  useGetEventsQuery,
} from '@/store/queries/notifications';
import { filteredUnreadNonLogonEvents } from '@/store/helpers/notifications';
import { FeatureVariableKeys } from '@/helpers/features';
import { useFeatureVariableValue } from '@/helpers/feature-provider';

type NotificationCountParams = {
  tasks: { assignedTasks: any[]; enrollmentTasks: any[] };
  notifications: any[];
  showEnrollmentTasks: boolean;
};

const getNotificationCount = ({
  tasks,
  notifications,
  showEnrollmentTasks,
}: NotificationCountParams) => {
  const assignedTasksCount = tasks?.assignedTasks?.length || 0;
  const enrollmentTasksCount = showEnrollmentTasks
    ? tasks?.enrollmentTasks?.length || 0
    : 0;
  const unreadNotificationsCount = filteredUnreadNonLogonEvents(notifications)?.length || 0;

  return assignedTasksCount + enrollmentTasksCount + unreadNotificationsCount;
};

function HeaderNotification({
  parentComponentName,
}: {
  parentComponentName: string;
}) {
  const logonEventShouldBeBypassed = getItem('logon-event-bypassed');
  const showEnrollmentTasks = useFeatureVariableValue<boolean>(
    FeatureVariableKeys.MessageCenterEnrollmentTasks,
    false,
  );

  const
    { data:
    { applicationId } = {

    }, isLoading: isApplicationQueryLoading } = useGetLatestApplicationIdQuery({
      personId: getPersonIdFromSessionStorage(),
    });

  const { data: tasks } = useGetTasksQuery(
    { personId: getPersonIdFromSessionStorage(), applicationId },
    { skip: isApplicationQueryLoading },
  );

  const { data: events } = useGetEventsQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const { data: notifications } = useGetNotificationsQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const notificationCount = getNotificationCount({
    tasks,
    notifications,
    showEnrollmentTasks,
  });

  const showAlertNotification = events?.loginCount > 0 && !logonEventShouldBeBypassed;

  return (
    <>
      <NotificationBell
        badgeContent={notificationCount}
        href={makeAbsoluteUrl('/message-center.html')}
        parentComponentName={parentComponentName}
      />
      <NotificationAlert
        open={showAlertNotification}
        title="You have an important message."
        content="Please review and take the appropriate action to move forward."
        buttonText="Continue to message"
        onClick={logonEventRedirect}
      />
    </>
  );
}

export default HeaderNotification;
